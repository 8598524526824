import React, {useEffect, useState} from 'react';
import imageSrc2 from '../images/SpeedyIndexing2.png'
import imageSrc1 from '../images/IndexingGo.png'
import imgSrc3 from '../images/Rocketship.png'
import { Link } from 'react-router-dom';




function SpeedyIndexing() {

    useEffect(() => {
        window.scrollTo(0, 40);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => setIsSlideInVisible(true), 500);
      return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
            
            <div className='sidebysidetitle'>
                <h2 className="mb-4">Speedy Indexing (5 mins)</h2>
                <img src={imgSrc3} alt='illustration' style={{width:'7%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>
                </div>
            <hr style={{color: 'blue'}}></hr>
            <br></br>
            <div className='fade-in'>
                <div className='infobox'>
             
                    <p><span className="fa fa-info-circle mr-3"></span> NOTE: For this activity, you will leave the Family Tree App and go to the BYU Family History and Technology 
                        Lab through this link <a href='https://indexing-go.familytech.byu.edu/' target='_blank' style={{color:'blue'}}>
                        https://indexing-go.familytech.byu.edu/</a> </p>
                </div>
            </div>

                <br></br>
                <br></br>
                <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                    <h4 className='mb-4'>Index from your Phone</h4>
                    <p>
                    Here's another chance to show the computer how smart you are. The Reverse Indexing program helps you teach the computer how to recognize smaller units of text. It's fast and you can index as much or as little as you want. As you index, you're teaching the system how to read so that many more records can be automatically indexed by computers in the future! 
                    </p>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <div className='sidebyside'>
                    <img className='fade-in' src={imageSrc1} alt="illustration" style={{width: '100%', height: 'auto'}}></img>
                </div>
                <br></br>
                <br></br>
                <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Let's Do It</span><br></br><br></br>
                Go to <a href='https://indexing-go.familytech.byu.edu/' target='_blank' color='blue'>https://indexing-go.familytech.byu.edu/</a> and take a couple of minutes to try basic indexing on your phone! 
                <br></br>
                The BYU Family History Technology Lab website has many other projects and service opportunities available to further the work of gathering Israel. Explore the rest of the website and try learning something new!</p>

            <br></br>
        
            </div>
            <br></br>
            <br></br>

            <div class='sidebyside'>
            <img src={imageSrc2} alt="illustration" style={{width: '50%', height: 'auto'}}></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"It is no coincidence that FamilySearch and other tools have come forth at a time when young people are so familiar with a wide range of information and communication technologies. Your fingers have been trained to text and tweet to accelerate and advance the work of the Lord—not just to communicate quickly with your friends. The skills and aptitude evident among many young people today are a preparation to contribute to the work of salvation."
            <br></br>
            <i style={{ color: 'blue' }}>
            -Elder David A. Bednar
            </i>
            </p>
            <br></br>
            <br></br>
        
            </div>
                
            <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Invitation</span><br></br><br></br>
                Schedule a time to take the names you find to the temple and invite a friend or family member to go with you! 
                If you want to talk about the temple with friends or family who are not members of the Church, consider inviting them to visit 
                the temple grounds with you where you can explain why we have temples and how you feel about them.
            </p>
            <br></br>

            </div>
            <br></br>
            <br></br>
            <hr style={{color: 'blue'}}></hr>
            <p>Good job! Continue to the next activity: <b>Get Involved App</b></p>
            <div style= {{textAlign: 'right'}}>
        <Link to="/getinvolvedapp">
        <button className='round-button'>Next Activity</button>
        </Link>
        </div>
            </div>
          
            
            
        
    );
}

export default SpeedyIndexing;