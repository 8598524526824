import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imageSrc1 from '../images/NavigatingTree1.png'
import imageSrc2 from '../images/NavigatingTree2.png'
import imageSrc3 from '../images/NavigatingTree3.png'
import imageSrc4 from '../images/NavigatingTree4.png'
import imageSrc5 from '../images/Compass2.png'

function NavigatingYourFamilyTree() {

    useEffect(() => {
        window.scrollTo(0, 40);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);


    return (
        <div className="p-4 p-md-5 pt-5">
            <div className='sidebysidetitle'>
                <h2 className="mb-4">Navigating Your Family Tree (5 mins)</h2>
                <img src={imageSrc5} alt='Compass' style={{width:'15%', height:'auto', marginLeft:'auto',padding:'0px'}}></img>
            </div>
           
            <hr style={{color: 'blue'}}></hr>
            <br></br>
            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
            <h4 className="mb-4">Knowing the Streets</h4>
            <p style={{color:'blue'}}>Family Tree App &gt; Tree</p>
            <p>Think of your family tree like a city. As you explore, you find lots of fun things to do, cool places to see, history to learn, and people to meet. Don't worry if you're not super familiar with where you're going--that's what exploring is for. Just start navigating!</p>
            </div>
            <br></br>
            <br></br>


            <div className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>
            <div class='graycolor2'>
                <br></br>
            <h4 className="mb-4">Let's Do It</h4>
            <p>Open the Family Tree app and take a few minutes to navigate around on the 'tree' tab. Look for the small blue filter icon to bring up different views (standard pedigree chart or fan chart), and at-a-glance information about where the people in your tree were born and who has source information, photos, or needed temple ordinances. </p>
            <br></br>
            <p>Note: To see the fan chart on an Android device, go to <span style={{color:'blue'}}>Settings &gt; App &gt; Enable Fan Chart</span></p>
            <br></br>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageSrc2} alt="illustration" style={{width: '40%', height: 'auto', padding: '10px'}}></img>
            <img src={imageSrc1} alt="illustration" style={{width: '40%', height: 'auto', padding: '10px'}}></img>
            </div>
            <br></br>
            <div class="sidebyside" style={{ display: 'flex', justifyContent: 'center',}}>
           
            <br></br>
            <p style={{ width :'300px'}}>
            <div className='h4 mb-4'>Check Out the Person Page</div>
            Now let's meet some people. Click on an ancestor from your tree and explore the different tabs (spouses, parents, sources, memories, etc.) on their person page to see what information is available.  
            </p>
            <img src={imageSrc3} alt="illustration" style={{width: '50%', height: 'auto',}}></img>
            </div>
            {/* <img src={imageSrc3} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}}></img>
            <img src={imageSrc4} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}}></img> */}
            </div>
            </div>
            <br></br>
            <div class='sidebyside'>
            <img src={imageSrc4} alt="illustration" style={{ width: '50%', height: 'auto', }} />
            <div>

            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"Navigating the family tree is fundamental to finding names for the temple. This skill will also increase your ability to help your friends learn more about their families."
                <p><i style={{color: 'blue'}}>~ FamilySearch</i></p>
                </p>

           
            </div>
            </div>
            <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>In your spare time, play with the Family Tree app to get familiar with the people in your tree. Then, share what you've discovered about an ancestor with a friend. What was their reaction? Did they want to know more about their family?</p>
            </div>
           
            <br></br>
            <br></br>
            <br></br>
            <br></br>


            <hr style={{color: 'blue'}}></hr>


        <p>Good job! Continue to the next activity: <b>A Walk Down Memory Lane</b></p>


        <div style= {{textAlign: 'right'}}>
        <Link to="/awalkdownmemorylane">
        <button className='round-button'>Next Activity</button>
        </Link>
        </div>


    </div>
   
    )


}


export default NavigatingYourFamilyTree;
