import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import vidSrc1 from '../videos/GiveSomeOneElseAHint.mp4'
import imageSrc1 from '../images/GiveSomeoneElseAHint1.png'
import imgSrc3 from '../images/Stickynote.png'




function GiveSomeoneElseAHint() {

    useEffect(() => {
        window.scrollTo(0, 35);
    }, []);

     // this is for the slide in effect
     const [isSlideInVisible, setIsSlideInVisible] = useState(false);
     useEffect(() => {
     const timer = setTimeout(() => setIsSlideInVisible(true), 500);
     return () => clearTimeout(timer);
     }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
            <div className='sidebysidetitle'>
            <h2 className="mb-4">Give Someone Else a Hint (7 mins)</h2>
                <img src={imgSrc3} alt='illustration' style={{width:'11%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>
                </div>
            <hr style={{color: 'blue'}}></hr>
            <br></br>
            <div className='fade-in'>
                <div className='infobox'>
             
                    <p><span className="fa fa-info-circle mr-3"></span> NOTE: For this activity, you will leave the Family Tree App and go to the BYU Record Linking Lab through this link <a href='https://mapapp.rll.byu.edu/map' target='_blank' style={{color:'blue'}}>
                    https://mapapp.rll.byu.edu/map</a> and look for <span style = {{fontWeight: 'bold'}}>Customized Family History Hints</span> </p>
                </div>
            </div>
                <br></br>
                <br></br>
                <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                    <h4 className='mb-4'>Customized Family History Hints</h4>
                    <p>
                    Attaching hints about your relatives helps you and anyone else who is part of your extended family. Usually, FamilySearch gives you plenty of hints to work on, but there's also a way to coax the system into giving you personally relevant hints on demand. 
                    <br></br>
                    <br></br>
                    It's called "Customized Family History Hints", and you can use it to further family history research, or as another way to help other FamilySearch patrons find their ancestors. The fun thing about this activity is you can ask the system to give you hints that are connected to a particular location or surname.
                    <br></br>
                    <br></br>
                    So, for example, if you have ancestors from Cook County, Illinois with the last name of Jones, you can ask the system to find records with hints that meet those criteria. 
                    <br></br>
                    <br></br>
                    Watch this video to learn how:
                    </p>
                </div>

                <br></br>
                <center><video src={vidSrc1} controls width="75%" height="auto" className='fade-in'></video></center>
                <br></br>
                <br></br>
                <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Let's Do It</span><br></br><br></br>
                Go to <a href='https://mapapp.rll.byu.edu/map' target='_blank' color='blue'>https://mapapp.rll.byu.edu/map</a> and look for Customized Family History Hints.
                <br></br> 
                <br></br>
                Take 2-3 minutes to search your surname, or maybe a specific country you have connections to, and attach record hints that appear on the map. Don't forget, you can view your relationship to see if they are your relatives. If they are, you can do temple work that is available for them!
                </p>
            <br></br>
            </div>
            <br></br>
            <br></br>
            <div class='sidebyside'>
            <img src={imageSrc1} alt="illustration" style={{width: '50%', height: 'auto'}}></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"The Lord has inspired technologies and tools that enable us to benefit from the oneness of missionary work and temple and family history work more than at any previous time in this dispensation. And it is no coincidence that these innovations have come forth at precisely the time they are so needed to advance missionary work all over the earth."
            <br></br>
            <i style={{ color: 'blue' }}>
            ~ Elder David A. Bednar
            </i>
            </p>
            <br></br>
            <br></br>
        
            </div>
            <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Invitation</span><br></br><br></br>
                Enlist your family to help you attach family history hints for everyone you can find with a family surname from your family tree. Then find a friend and help them do the same thing with surnames in their family tree. 
                <br></br>
                <br></br>
                Pro Tip: You may find it easier to do this activity on a computer, but for convenience your mobile device will work just fine. 
            </p>
            <br></br>

            </div>
            <br></br>
            <br></br>
            <hr style={{color: 'blue'}}></hr>
            <p>Good job! Continue to the next activity: <b>For the Record</b></p>
            <div style= {{textAlign: 'right'}}>
        <Link to="/fortherecord">
        <button className='round-button'>Next Activity</button>
        </Link>
        </div>

    </div>
    );
}

export default GiveSomeoneElseAHint;