import React from 'react';

function Header() {
  return (
    <header className="site-header js-site-header">
      <div className="logo">
        {/* <a href="index.html" className="logo">The Guide to Gathering Israel <span>President Nelson</span></a> */}
      </div>
    </header>
  );
}

export default Header;