import React, {useEffect, useState} from 'react';
import flipcard from '../components/flipcard';
import imgsource1 from '../images/MemoryHeadstone.png'
import imgsource2 from '../images/MemoryBirthCert.png'
import imgsource3 from '../images/MemoryAnimal.png'
import imgsource4 from '../images/MemoryWedding.png'
import imgsource5 from '../images/MemoryMustache.png'
import imgsource6 from '../images/MemoryYou.png'
import imageSrc7 from '../images/NavigatingTree4.png'
import imageSrc8 from '../images/MemoryPersonPg.png'
import imageSrc9 from '../images/Pathway.png'
import { Link } from 'react-router-dom';




function AWalkDownMemoryLane() {

    useEffect(() => {
        window.scrollTo(0, 40);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => setIsSlideInVisible(true), 500);
      return () => clearTimeout(timer);
    }, []);

    return (
        
            <div className="p-4 p-md-5 pt-5">
            <div className='sidebysidetitle'>
                <h2 className="mb-4">A Walk Down Memory Lane (5 mins)</h2>
                <img src={imageSrc9} alt='illustration' style={{width:'20%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>

            </div>
            <hr style={{color: 'blue'}}></hr>
            <br></br>

            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                <h4 className="mb-4">Remember, Remember</h4>
                <p style={{color:'blue'}}>Family Tree App &gt; Person Page of an Ancestor &gt; Memories</p>
                <br></br>
                <p>Now that you know your way around  your family tree, 
                    take a minute to learn more about the people there. Who were they? What were they like? How did they live?</p>
                <br></br>
                <p>Use the memories tab on the person page of one of your ancestors and see what you can discover, like stories, photos or documents. To learn who put those there, click on a memory item to see the name of the contributor. 
                    Is it someone you know or recognize, or is it possibly a cousin who is also contributing information about their ancestor?</p>
            </div>

            <br></br>

            <div class='graycolor2'>
                <br></br>
            <h4 className="mb-4">Let's Do It</h4>
            <p>Try to find photos of the following things in the memories of your ancestors! In a group setting, try making this activity a competition to see who can find these things first.</p>
         
            <div className='sidebyside'>
            <br></br>
            <img src={imgsource1} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>
            <img src={imgsource2} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>
            <img src={imgsource3} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>
           
            
            </div>
            <div className='sidebyside'>
            <img src={imgsource4} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>
            <img src={imgsource5} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>
            <img src={imgsource6} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>

            </div>
            <div></div>
            
            <br></br>
            <br></br>
            <p>What types of memories did you like to see when looking at your ancestor's memories? Do you
             have memories of deceased relatives in your home that others would benefit from seeing or reading? Think of the types of 
             memories that you could add to your own profile that would benefit your posterity.</p>
             <br></br></div>
             
            <br></br>
            <br></br>
            <br></br>
            <div>
            
                <h4>How do I add a Memory?</h4>
                <br></br>
                <p>Adding a memory is simple. Try it out by going to the person page of whoever you want to add the memory to. This could be your own page or that of an ancestor. After that, open the memories tab and tap the blue plus button!</p>
            </div>
            <br></br>
            
            <div class='sidebyside'>
            <img src={imageSrc7} alt="illustration" style={{ width: '50%', height: 'auto', }} />
            <div>
            <h4 className='mb-4'>How is this Gathering Israel?</h4>
            <p>
            "Embrace your sacred memories. Believe them. Write them down. Share them with your family. Trust that they come to you from your Heavenly Father and His Beloved Son."

                <br></br><i style={{color: 'blue'}}>~ Elder Neil L. Andersen</i>
            </p>
            </div>
       
        </div>
        <br></br>
        
        <div className='sidebyside graycolor2'>
        
            <p style={{marginRight: '50px'}}><span className='h4 mb-4'>Invitation</span><br></br><br></br>We all want to be remembered. If you have a memory of a deceased relative, add it to the family tree. Record a favorite 
                experience, take a picture of a family heirloom, or just upload a selfie of you and a friend to your own memories 
                page.<br></br>

                A benefit of adding photos of yourself now is that you get to choose what photos and documents you want your family to 
                see after you've passed on. <br></br><br></br>

                Pro Tip: Only you will be able to see the memories that you add to yourself or other living people. Memories added to
                 deceased individuals are visible to everyone.
            </p>
            <img src={imageSrc8} alt="photo" style={{width: '50%', height: 'auto', paddingLeft: '15px'}}></img>
        
        </div>
       <br></br>
       <br></br>

       <hr style={{color: 'blue'}}></hr>

       <p>Good job! Continue to the next activity: <b>Making Connections</b></p>
        <br></br>
       <div style= {{textAlign: 'right'}}>
       <Link to="/MakingConnections">
       <button className='round-button'>Next Activity</button>
       </Link>
       </div>
        </div>
        
    );
}


export default AWalkDownMemoryLane;