import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import vidSrc1 from '../videos/Reverse Indexing Video.mp4'
import imageSrc1 from '../images/MakeComputersSmarter1.png'
import imageSrc2 from '../images/SmartComputer2.png'





function MakeComputersSmarter() {

    useEffect(() => {
        window.scrollTo(0, 58);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => setIsSlideInVisible(true), 500);
      return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
            <div className='sidebysidetitle'>
                <h2 className="mb-4">Make Computers Smarter (5 mins)</h2>
                <img src={imageSrc2} alt='illustration' style={{width:'25%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>

            </div>
           
            <hr style={{color: 'blue'}}></hr>
            <br></br>
            <div className='fade-in'>
                <div className='infobox'>
             
                    <p><span className="fa fa-info-circle mr-3"></span> NOTE: For this activity, you will leave the Family Tree App and go to the BYU Family History Technology Lab through this link <a href='https://indexing.familytech.byu.edu/home' target='_blank' style={{color:'blue'}}>
                    https://indexing.familytech.byu.edu/home</a> </p>
                </div>
            </div>
                <br></br>
                <br></br>
                <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                <h4 className='mb-4'>Find indexing hard? Reverse the process!</h4>
                <p>
                Do you know indexing? That activity where you read old records and type information from them so the computer can search and find it? That's a very worthwhile way to serve (check it out under Indexing on FamilySearch.org), but Reverse Indexing is different--and way easier! 
                <br></br>
                <br></br>
                With Reverse Indexing you just point and click. The program shows you 12 pictures of handwritten names and you mark which ones don't match. Every time you identify a mismatch, you teach the computer a little more about how to recognize variations in handwriting. Believe it or not, computers are getting really good at reading handwriting, and they do it super fast, which is a game-changer for people trying to find their ancestors in old records. But computers are only as smart as people make them. Adding your brain power to the equation will speed up that process!
                <br></br>
                <br></br>
                Watch the video below to see how it works:
                </p>
                </div>
                <br></br>
                <div className='fade-in'>
                <center><video src={vidSrc1} controls width="75%" height="auto"></video></center>
                </div>
                <br></br>
                <br></br>
                <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Let's Do It</span><br></br><br></br>
                Go to <a href='https://indexing.familytech.byu.edu/home' target='_blank' color='blue'>https://indexing.familytech.byu.edu/home</a> and take a few minutes to try reverse indexing on your phone. For fun, try typing your own last name in the search bar to see if there are matching records that need to be reviewed. As you practice, you will get better at recognizing your last name in cursive--and might even consider improving your own signature!
                
                </p>

            <br></br>
           
            
        
            </div>
            <br></br>
            <br></br>
            <div class='sidebyside'>
            <img src={imageSrc1} alt="illustration" style={{width: '50%', height: 'auto'}}></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"This work is a spiritual work, a monumental effort of cooperation on both sides of the veil, where help is given in both directions. Anywhere you are in the world, with prayer, faith, determination, diligence, and some sacrifice, you can make a powerful contribution. Begin now. I promise you that the Lord will help you find a way. And it will make you feel wonderful."
            <br></br>
            <i style={{ color: 'blue' }}>
            ~ Richard G. Scott
            </i>
            </p>
            <br></br>
            <br></br>
        
            </div>
            <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Invitation</span><br></br><br></br>
                Share this activity with a friend! If they can read cursive and they have a computer or smartphone, they can do Reverse Indexing. It might just be the thing they need to get curious about learning more about their family. 
            </p>
            <br></br>

            </div>
            <br></br>
            <br></br>
            <hr style={{color: 'blue'}}></hr>
            <p>Good job! Continue to the next activity: <b>Speedy Indexing</b></p>
            <div style= {{textAlign: 'right'}}>
        <Link to="/speedyindexing">
        <button className='round-button'>Next Activity</button>
        </Link>
        </div>
        
        
    </div>
                
    );
}

export default MakeComputersSmarter;
