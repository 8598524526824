import React, {useEffect, useState} from 'react';
import imgSource1 from '../images/FamilySearchChat.png'
import imgSource2 from '../images/AnotherWarningSign.png'
import imageSrc3 from '../images/MeetNewFriends.png'
import imgSrc3 from '../images/Jumprope.png'
import { Link } from 'react-router-dom';




function MeetNewFriends() {

    useEffect(() => {
        window.scrollTo(0, 35);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => setIsSlideInVisible(true), 500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
        
        <div className='sidebysidetitle'>
            <h2 className="mb-4">Meet New Friends (5 mins)</h2>
                <img src={imgSrc3} alt='illustration' style={{width:'23%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>
                </div>
        <hr style={{color: 'blue'}}></hr>
        <br></br>

        <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
            <h4 className="mb-4">Finding Living Relatives</h4>
            <p style={{color:'blue'}}>Family Tree App &gt; More Menu &gt; Chat</p>
        </div>
            
        <div className='sidebyside'>
            
            <p className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>You know how to search and find relatives who have passed away, but what about the ones that are still living? Memories from living people who knew your ancestors can be priceless treasures, and collaborating with those living relatives can be a blessing to people on both sides of the veil.

             <br></br>
             <br></br>
        
                
                Under the Chat tab in the More menu, or from within an ancestor's record in the family tree, you can have conversations with others who are also on FamilySearch. Sometimes you just want to say "thanks." Other times you might want to ask for more information. You might need to kindly point out a mistake or a question you have on their work. Regardless, this is a great opportunity to connect to the community and get to know who else is doing this great work.
        
        <br></br>
        <br></br>
        When you look at a source or memory that has been attached to your ancestor, see which user made that contribution. Click on the name and you can see your relationship (or request to see your relationship) to them. Then don't be afraid to reach out and send them a message! Responses will come to you in the Chat section under the More menu.
        </p>
        <img className='fade-in' src={imgSource1} alt='illustration' style={{width: '35%', height: 'auto'}}></img>
        </div>

        <br></br>
        <br></br>
        <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
        <div className='graycolor2'>

                    <br></br>
                <p><span className='h4 mb-4'>Let's Do It</span><br></br><br></br>
                Explore the Chat section. If there are no messages there, find a favorite ancestor in the family tree and look for a meaningful photo, story or other memory. Send a thank-you note to them for taking the time to add that item to the family tree, then see if they respond.

               </p>
        </div>
        </div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>

        <div className='sidebyside infobox'>
            <img src={imgSource2} alt='illustration'style={{width: '20%', height: 'auto', marginRight: 40}}></img>
            <p>NOTE: Just because this site should be safe doesn’t mean that it will be! Don’t hesitate to report any suspicious behavior by tapping on the three dots in the top right corner to report abuse or block any users that aren’t using it as intended. Technology is a blessing, but make sure you are using this chat appropriately to connect family members and gain information about your ancestors.
</p>
        </div>

        <br></br>
        <br></br>

        <div className='sidebyside'>
            <img src={imageSrc3} alt="illustration" style={{width: '50%', height: 'auto'}}></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"While temple and family history work has the power to bless those beyond the veil, it has an equal power to bless the living. It has a refining influence on those who are engaged in it. They are literally helping to exalt their families."<br></br>
            <span style={{color: 'blue', fontStyle: 'italic'}}>~ President Russell M. Nelson</span></p>
        </div>

    <div className='graycolor2'>
       <br></br>
        <p><span className='h4 mb-4'>Invitation</span><br></br><br></br>
        Message someone who has made a contribution to your family tree! Saying "thanks" is a great reason to reach out. Finding living relatives can be just as important as finding deceased ones. Ask them what additional memories they may have about your common ancestor. You may be surprised at the conversation that follows!
            </p>
            <br></br>
    </div>

            <br></br>
            <br></br>

            <hr style={{color: 'blue'}}></hr>
            <p>Good job! Continue to the next activity: <b>Give Me a Hint!</b></p>
            <div style= {{textAlign: 'right'}}>
        <Link to="/givemeahint">
        <button className='round-button'>Next Activity</button>
        </Link>
        </div>


        </div>
    );
}

export default MeetNewFriends;