import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imageSrc2 from '../images/MakingConnections_1.png';
import imageSrc1 from '../images/Find_A_Person.png';
import vidSrc1 from '../videos/MakingConnectionsVideo.mp4';
import imageSrc3 from '../images/MakingConnectionsTOP.png';


function MakingConnections() {

    useEffect(() => {
        window.scrollTo(0, 40);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => setIsSlideInVisible(true), 500);
      return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
            
            <div className='sidebysidetitle'>
                <h2 className="mb-4">Making Connections (5 mins)</h2>
                <img src={imageSrc3} alt='illustration' style={{width:'40%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>

            </div>
            <hr style={{color: 'blue'}}></hr>
            <br></br>

            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>

                <h4 className="mb-4">Find a Person</h4>
                <p style={{color:'blue'}}>Family Tree App &gt; Tree &gt; Search the icon in the top right hand corner</p>
                <p>Let's say you want to help a friend connect with their ancestors, but they don't have the Family Tree app or a FamilySearch account. What can you do?</p>
                <p>"Find a Person" lets you search for any deceased person in the family tree. This way you can search for one of your friend's ancestors and maybe help them learn something about their family they didn't know. You might also find that you're related through a common ancestor!</p>
                <p>Watch this video to see how Sarah includes "Find a Person" in a casual conversation with her friend Kayla.</p>
            </div>

            <br></br>

            {/* <div className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}> */}
            <div className='fade-in'>
            <center><video src={vidSrc1} controls width="75%" height="auto"></video></center>
            </div>

            <br></br>
            <br></br>
            <br></br>

            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
            <div className="graycolor2">
            <div className='sidebyside'>
            <p>
                <h4 className="mb-4">Let's Do It</h4>
                Practice using "Find a Person" with a friend. If you find one of their ancestors, 
                tap on the three dots in the top right of the screen. Tap "View My Relationship" 
                to see if you are related to your friend's ancestor. Ask what they know about their 
                ancestor and see where the conversation leads.
                <br></br>
                <br></br>
                Sometimes the person you are looking for won't be in the family tree. In this case, 
                ask your friend for information about another relative. Or offer to do some deeper 
                research for them on your own or with the help of a family history consultant.
            </p>
            <img src={imageSrc1} alt="illustration" style={{width: '50%', height: 'auto'}}></img>
            </div>
            </div>
            </div>
            <br></br>
            <br></br>

            <div>
           
            <div class='sidebyside'>
            <img src={imageSrc2} alt="illustration" style={{width: '50%', height: 'auto'}} className="fade-in"></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"Family history is a powerful resource to use in finding those whom the Lord is preparing to receive the restored gospel . . . People typically have spiritual feelings as they talk about families. People also feel the Spirit as they are taught about the doctrine concerning why we do family history work." <br></br><i style={{color: 'blue'}}>~ Preach My Gospel</i></p>
            </div>
            </div>
            <br></br>

            <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>Think of somebody who would enjoy finding their place in the family tree and help them using "Find a Person". What was their reaction? Were they interested in learning more? Did you make a family connection with them? How did it make you feel?</p>
            </div>
            <br></br>
            <br></br>

            <hr style={{color: 'blue'}}></hr>

            <p>Good job! Continue to the next activity: <b>Where in the World?</b></p>

            <div style= {{textAlign: 'right'}}>
            <Link to="/WhereInTheWorld">
            <button className='round-button'>Next Activity</button>
            </Link>
            </div>

        </div>
    );
}

export default MakingConnections;