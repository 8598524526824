import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imgSrc1 from '../images/WhereInTheWorld1.png'
import imgSrc2 from '../images/WhereInTheWorld2.jpg'
import imgSrc3 from '../images/WhereInTheWorld3.jpg'
import imgSrc4 from '../images/WhereInTheWorld4.png'
import imgsrc5 from '../images/LilGlobe.png'


function WhereInTheWorld() {


    useEffect(() => {
        window.scrollTo(0, 50);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
        <div className='sidebysidetitle'>
                <h2 className="mb-4">Where in the World? (5 min)</h2>
                <img src={imgsrc5} alt="globe" style={{width: '7%', height: 'auto', marginLeft:'auto', padding: '0px'}}></img>
                </div>
        <div/>
        <hr style={{color: 'blue'}}></hr>
        <br></br>
        <div/>


        <div className='sidebyside'>
            <div style={{width: "150%", marginLeft: '50px'}} className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>
            <h3 className="mb-4">Map My Ancestors</h3>
            <p style={{color:'blue'}}>Family Tree App &gt; More Menu &gt; Map My Ancestors</p>
            <p>Ever wonder how your family ended up where it did? Map My Ancestors uses information about your ancestors to create a world map showing their birth, death, residence, and marriage locations. It also shows their migration routes.</p>
            </div>
            <div>
            <img src={imgSrc1} alt="illustration" style={{width: '100%', height: 'auto', padding: '10px', alignItems: 'right'}} className='fade-in'></img>
            </div>
        </div>


        <br></br>
        <br></br>


        <div className='sidebyside'>
            <img src={imgSrc2} alt='illustration' style={{width: '40%', height: 'auto', padding: '10px', marginRight: '50px', marginBottom: '25px'}} className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}></img>
            <img src={imgSrc3} alt='illustration' style={{width: '40%', height: 'auto', padding: '10px', marginLeft: '50px'}} className={`slide-in ${isSlideInVisible ? 'active' : ''}`}></img>
        </div>


       
        <div className="graycolor2" style={{marginTop: '100px'}}>
            <br></br>
        <h4 className="mb-4">Let's Do It</h4>
        <p>Get out your Family Tree (m)app! Go to More &gt; Map My Ancestors and just start tapping on whatever looks interesting. Explore the different map types and see where your ancestors got their start. Are you surprised? Have you been to some of those places where your ancestors lived?</p>
        <br></br>
        </div>




        <div className='sidebyside'>
            <img src={imgSrc4} alt='illustration' style={{width: '50%', height: 'auto'}}></img>
            <p style={{marginLeft: '100px', marginRight: '100px'}}><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"The Mayo Clinic recently noted: 'Having a sense of belonging is so important. … Nearly every aspect of our lives is organized around belonging to something.' This report adds, 'We cannot separate the importance of a sense of belonging from our physical and mental health'—and, I would add, our spiritual health."
             <br></br>
             <i style={{color: 'blue'}}> ~ Elder M. Russel Ballard</i></p>
        </div>




        <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>Share what you discovered in this activity with someone else.
                Ask where they are from and help them map the migration routes of their ancestors.
                Be prepared to help them download the Family Tree app, create an account, and build
                their family tree if they haven't done so already. Then ask if they'd like to learn more about their family.</p>
            </div>


            <br></br>
            <br></br>


            <hr style={{color: 'blue'}}></hr>


            <p>Good job! Continue to the next activity: <b>My Claim To Fame</b></p>


            <div style= {{textAlign: 'right'}}>
            <Link to="/MyClaimToFame">
            <button className='round-button'>Next Activity</button>
            </Link>
            </div>


        </div>


    );
}


export default WhereInTheWorld;