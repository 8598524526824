import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imageSrc1 from '../images/GiveMeAHint.png';
import imgSrc2 from '../images/GiveMeAHint_1.png'
import imageSrc3 from '../images/Hint1.png';
import imageSrc4 from '../images/Hint2.png';
import imageSrc5 from '../images/Hint3.png';
import vidSrc1 from '../videos/Give Me A Hint.mp4';
import imgSrc3 from '../images/Lightbulb.png';




function GiveMeAHint() {

    useEffect(() => {
        window.scrollTo(0, 65);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
            
            <div className='sidebysidetitle'>
            <h2 className="mb-4">Give Me a Hint (10-15 mins)</h2>
                <img src={imgSrc3} alt='illustration' style={{width:'9%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>
                </div>
            <hr style={{color: 'blue'}}></hr>
            <br></br>
            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                <h4 className='mb-4'>Are You Ready to Challenge Yourself?</h4>
                <p>In these next few activities, we're going to give you some bigger challenges. These skills will help you to be more effective at finding your family and more helpful to friends who want to find their missing relatives.</p>
            </div>    
                
                <center><img src={imgSrc2} style={{width: '30%', height: 'auto'}} className='fade-in'></img></center>
            
            <div className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}> 
                <h4 className='mb-4'>A Blue Clue</h4>
                <p style={{color:'blue'}}>Family Tree App &gt; Tasks</p>

                <p>Sometimes you just need a hint when looking for your ancestors. Look for these blue icons in the family tree next to your family members. If you see one, it means that FamilySearch has found a record that might include one or more of your family members. These records can contain information needed to submit a person's name to the temple, or for connecting them to another generation.</p>
                <p>Hints about your ancestors are consolidated under the Tasks menu on the Family Tree app. Accepting or rejecting a record hint only takes a couple of minutes. Your job is to compare the record (birth, death, census, marriage, etc.) and decide if it matches what you already know about an ancestor.</p>
                <p>As you attach record hints, you will see images like the ones below. Tap on the green arrow to add information from the record into the person's profile. If someone on the record isn't in the tree, go ahead and add them.</p>
                <p>If you are unsure whether the information matches, don't be afraid to do a little extra research. Or, skip the record for now and let someone else take care of it. Don't feel like you have to be an expert, just make your best judgement.</p>
                <br></br>
                <center><img src={imageSrc3} alt="illustration" style={{width: '50%', height: 'auto', padding: '10px'}} className="fade-in"></img>
                <img src={imageSrc4} alt="illustration" style={{width: '50%', height: 'auto', padding: '10px'}} className="fade-in"></img>
                <img src={imageSrc5} alt="illustration" style={{width: '50%', height: 'auto', padding: '10px'}} className="fade-in"></img>
                <br></br></center>
                <p>Watch the following video to see a demo on how to attach Record Hints:</p>
                <br></br>
                </div>
                
                <center><video src={vidSrc1} controls width="75%" height="auto"></video></center>
                <br></br>


            <div>
                <h4 className='mb-4'>Reason Statements</h4>
                <p>A reason statement is a field that you include when attaching a record. It helps you to communicate your reasoning for making a change or an addition to the family tree. You want to be specific and concise. For example, "looks good to me" is not as good as "all data matches the person's record".</p>
                <br></br>
            </div>

            <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Let's Do It</span><br></br><br></br>
                In the tasks menu on your tree, take a few minutes to become familiar with attaching Record Hints. Be careful that you compare several data points about a person before deciding that the record is a match.
                </p>
            <br></br>
            </div>

            <br></br>
            <br></br>

            <div class='sidebyside'>
            <img src={imageSrc1} alt="illustration" style={{width: '50%', height: 'auto'}}></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"We have begun using information technology to hasten the sacred work of providing ordinances for the deceased. The role of technology . . . has been accelerated by the Lord himself, . . . however, we stand only on the threshold of what we can do with these tools."
            <br></br>
            <i style={{ color: 'blue' }}>
            ~ Elder Quentin L. Cook
            </i>
            </p>
            <br></br>
            <br></br>
            </div>

            <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Invitation</span><br></br><br></br>
                New hints show up all the time. Commit to take a few minutes each day to check your outstanding tasks. When you see them, take a few minutes to review and attach records to your ancestors. As you do so, you will be helping the system to recognize other possible connections for yourself and for others. This skill will also teach you how to help others who are looking for their ancestors. 
                <br></br>
            </p>
            <br></br>

            </div>
            <br></br>
            <br></br>
            <hr style={{color: 'blue'}}></hr>
            <p>Good job! Continue to the next activity: <b>Give Someone Else a Hint</b></p>
        
        <div style= {{textAlign: 'right'}}>
        <Link to="/givesomeoneelseahint">
        <button className='round-button'>Next Activity</button>
        </Link>
        </div>


        </div>
    );
}

export default GiveMeAHint;