import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imageSrc1 from '../images/YoureRelatedtoMe_1.jpg';
import imageSrc2 from '../images/YoureRelatedtoMe_2.png'
import imageSrc3 from '../images/SmallPhones2.png'



function YoureRelatedtoMe() {

    useEffect(() => {
        window.scrollTo(0, 55);
    }, []);

    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => setIsSlideInVisible(true), 500);
      return () => clearTimeout(timer);
    }, []);


    return (
        <div className="p-4 p-md-5 pt-5">
            <div className='sidebysidetitle'>
                <h2 className="mb-4">You're Related to Me?! (7 mins)</h2>
                <img src={imageSrc3} alt='Phones' style={{width:'25%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>

            </div>
            
            <hr style={{color: 'blue'}}></hr>
            <br></br>

            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
            <h4 className="mb-4">We Are All Related to Each Other.</h4>
            <p>Have you ever wondered if you are related to people around you?
                Get ready to find out! You will need the Family Tree app for this activity.
                If you don't have it yet, download it here:
                <ul>
                    <li className={`slide-in-2 ${isSlideInVisible ? 'active' : ''}`}><a href="https://apps.apple.com/us/app/familysearch-tree/id885982973#:~:text=Free-,View,-in%20Mac%20App" target="_blank">App Store</a></li>
                    <li className={`slide-in-3 ${isSlideInVisible ? 'active' : ''}`}><a href="https://play.google.com/store/search?q=familysearch&c=apps" target="_blank">Google Play</a></li>
                </ul>
            </p>
            </div>


            <br></br>
            <br></br>

            <div class='sidebyside'>
            <img src={imageSrc1} alt="illustration" style={{width: '40%', height: 'auto'}} className='fade-in'></img>

            <div className={`slide-in-2 ${isSlideInVisible ? 'active' : ''}`}>
                <h4 style={{textAlign: 'left', paddingLeft: "30px"}}>Let's Do It</h4>
                <p style={{color:'blue', textAlign: 'left', paddingLeft: "30px"}}>More Menu &gt; Relatives Around Me &gt; Scan for relatives</p>
                <p style={{paddingLeft: "30px"}}>In the Family Tree app, open Relatives Around Me and click "Scan for relatives". If your friends are scanning at the same time,
                    you'll see how you are all related! You can even follow your family trees back up to your common ancestor! Did you find a new relative?
                    Who is your closest cousin?
                </p>
            </div>
            </div>
            
            <br></br>
            <br></br>
            <br></br>

            <div style={{paddingLeft: '2rem', paddingRight: '2rem'}}>
            <div className='graycolor2'>
            <br></br>
            <h4 className="mb-4">So what just happened?</h4>
            <p>FamilySearch is creating one giant family tree of mankind, a shared database that shows how we are all connected 
                through family relationships.</p>
            <p>By adding information about their deceased relatives, <i>anyone</i> can connect to the family tree. FamilySearch uses this
                information to look for common connections. When a common ancestor between two people is identified, 
                their relationship to one another can be identified as well!</p>

            <br></br>
            <br></br>

            <h4 className="mb-4">Why didn't I find any relatives?</h4>
            <p>If you didn't find anyone that's related to you, don't worry! It probably means that there are gaps 
                or "missing branches" in the family tree. Later, we will show you ways to fill those gaps.</p>
                <br></br>
            </div>
            </div>
            <br></br>
            
            

            <div>
            
            <div class='sidebyside'>
            <img src={imageSrc2} alt="illustration" style={{width: '50%', height: 'auto'}}></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"We want you to connect with your family. We want you to connect with each other. Why? Because we've learned 
                when we connect and belong to each other, we treat each other differently."
                <p><i style={{color: 'blue'}}>~ Steve Rockwood, CEO, FamilySearch</i></p>
                </p>
            </div>
            </div>
            <br></br>

            <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>Find a friend who is connected to the family tree and try Relatives Around Me with them. 
                Did you make a family connection? What was their response and how did it make you feel?
                 Did it make you think of them differently?
                 <br></br> 
                 <br></br>
                 Pro tip: You can do this activity anywhere. 
                 Just remember that those participating nearby will also need to be logged into the Family Tree app 
                 and connected to their ancestors in the Family Tree.
                 <br></br> 
                 <br></br>
                 Later, in the Making Connections activity, 
                 you will learn how to find out if you are related to someone who doesn't have the Family Tree app.
                </p>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <hr style={{color: 'blue'}}></hr>

            <p>Good job! Continue to the next activity: <b>Navigating Your Family Tree</b></p>

            <div style= {{textAlign: 'right'}}>
            <Link to="/navigatingyourfamilytree">
            <button className='round-button'>Next Activity</button>
            </Link>
            </div>

        </div>
    );
}

export default YoureRelatedtoMe;