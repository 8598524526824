import React, {useEffect, useState} from 'react';
import imgSrc1 from '../images/ForTheRecord_1.jpg';
import imgSrc2 from '../images/ForTheRecord_2.jpg';
import imgSrc3 from '../images/ForTheRecord_3.png';
import imgSrc4 from "../images/Pencil.png"
import vidSrc1 from '../videos/ForTheRecordVideo.mp4';



function ForTheRecord() {

    useEffect(() => {
        window.scrollTo(0, 35);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
            <div>
            <div className='sidebysidetitle'>
            <h2 className="mb-4">For The Record...(7 min)</h2>
                <img src={imgSrc4} alt='illustration' style={{width:'20%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>
                </div>
                <hr style={{color: 'blue'}}></hr>
                <br></br>
            </div>

            <div>
                <h4 className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>Search Historical Records</h4>
                <p className={`slide-in ${isSlideInVisible ? 'active' : ''}`} style={{color:'blue'}}>Family Tree App &gt; More Menu &gt; Search Historical Records</p>
                <p className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>Still trying to find more information about your ancestors? You can search historical records 
                    on FamilySearch for additional details. This feature is easier to use on a computer, 
                    but it is still a good idea to learn how to use it on the app.</p>
            </div>

            <div>
                <br></br>
                <h5 className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>What are Historical Records?</h5>
                <p className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>Historical records are recorded details of people's lives. Governments, churches, schools, 
                    and many other entities have been keeping track of the people they serve for hundreds of years. 
                    Many of these records are lost to time and memory, but many have been preserved in archives. 
                    FamilySearch and other family history organizations and companies are gathering, photographing, 
                    and publishing these records as fast as possible.  </p>
                <br></br>
                <p className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>Today you can find online records of births, marriages, deaths, and many other life events for 
                    literally billions of people. Powerful search engines, along with a small, but simple thing you may have 
                    heard of called <b>indexing</b>, make it possible to sort through these records in seconds to find the 
                    precious information you need. </p>
                <br></br>
                <p className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>When you find a record about one of your family members, be sure to attach it to that person in 
                    your family tree so you can add to the known information about that person.</p>
            </div>
            <br></br>
            <br></br>

            <div className='sidebyside'>
                <img src={imgSrc1} alt='illustration' style={{width: '45%', height: 'auto'}} className='fade-in'></img>
                <img src={imgSrc2} alt='illustration' style={{width: '45%', height: 'auto'}} className='fade-in'></img>
            </div>

            <div>
                <br></br>
                <br></br>
                <p>Watch this video to see how it works:</p>
                <br></br>
                <center><video src={vidSrc1} controls width="75%" height="auto"></video></center>
            </div>

            <div className="graycolor2" style={{marginTop: '80px'}}>
            <h4 className="mb-4">Let's Do It</h4>
            <p>Search the historical records for one of your ancestors who doesn't have many information sources 
                attached to them. Attach the record and its information to your ancestor. Practice using this feature
                 by trying different search approaches, such as adding more information to narrow the search, or using 
                 less information to broaden your search. </p>
            </div>

            <div class='sidebyside'>
            <img src={imgSrc3} alt="illustration" style={{width: '50%', height: 'auto'}} className="fade-in"></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"The Spirit of Elijah affects people inside and outside of the Church. However, as members of Christ’s restored Church, we have the covenant responsibility to search out our ancestors and provide for them the saving ordinances of the gospel. 'They without us should not be made perfect' (Hebrews 11:40; see also Teachings: Joseph Smith, 475). And 'neither can we without our dead be made perfect' (D&C 128:15)." <br></br><i style={{color: 'blue'}}>~ Elder David A. Bednar</i></p>
            </div>

            <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>When you feel confident, try your new searching skills by helping a friend look for information about one of 
                their ancestors. Once you find a record, you can help them attach it to their family tree, or add that 
                ancestor to the family tree if they're not in there already. </p>
            <br></br>
            <p>When you find a record of your friend's ancestor, give them a moment to look it over and discover what it says. 
                Don't be surprised if you hear expressions of amazement and possibly even reverence. It is at times like 
                this that the Spirit may be whispering to them the importance of family connections and testifying that 
                family bonds can be eternal.</p>
            </div>

            <br></br>
            <hr style={{color: 'blue'}}></hr>
            <br></br>
            
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Congrats! You've finished all the activities!</p>




        </div>






    );
}

export default ForTheRecord;