import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imageSrc1 from "..//images/presidentnelson.png"


function Home() {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);


    return (
        
        <div className="p-4 p-md-5 pt-5" >
            <h2 className="mb-4">Who Me, Gather Israel?</h2>
            <hr style={{ color: 'blue' }}></hr>
            <br></br>
            <br></br>
            <div>
{/* ඞ */}
           
            <div class='sidebyside'>
            <img src={imageSrc1} alt="illustration" style={{width: '25%', height: 'auto'}} className="fade-in"></img>
            <p className={`slide-in ${isSlideInVisible ? 'active' : ''}`} style={{marginLeft: '20px'}}>"Anytime you do anything that helps anyone—on either side of the veil—take a step toward making covenants with God and receiving their essential baptismal and temple ordinances, you are helping to gather Israel. It is as simple as that."
            <br></br> 
            ~ President Russell M. Nelson</p>
            </div>
            </div>

            <br></br>
            <br></br>
            <div className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>
            <div className='graycolor2'>
            <p>
            Connecting with your ancestors is a great way to help "gather" God's children on the other side of the veil. But did you 
            know it is also an easy and fun way to get your friends thinking and talking about eternal things here and now? Try the 
            activities below and see for yourself!
            </p>
           
            </div>
            </div>
            <br></br>
            <br></br>
            <div style= {{textAlign: 'center'}}>
            <Link to="/readysetgo">
            <button className='round-button'>Get Started!</button>
            </Link>
            </div>
        </div>
        
    );
}

export default Home;
