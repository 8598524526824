import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/NavBar';
import Header from './components/Header';
import Home from './Pages/Home';
import ReadySetGo from './Pages/ReadySetGo';
import SharingStories from './Pages/SharingStories';
import TheStoryofYou from './Pages/TheStoryofYou';
import ComingSoon from './Pages/ComingSoon';
import './bootstrap-sidebar-05/sidebar-05/css/bootstrap.min.css'; 
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import YoureRelatedtoMe from './Pages/YoureRelatedtoMe';



import AWalkDownMemoryLane from './Pages/AWalkDownMemoryLane';
import MakingConnections from './Pages/MakingConnections';
import WhereInTheWorld from './Pages/WhereInTheWorld';
import MyClaimToFame from './Pages/MyClaimToFame';
import ATripToTheTemple from './Pages/ATripToTheTemple';
import AMatchingGame from './Pages/AMatchingGame';
import MakeComputersSmarter from './Pages/MakeComputersSmarter';
import SpeedyIndexing from './Pages/SpeedyIndexing';
import GetInvolvedApp from './Pages/GetInvolvedApp';
import MeetNewFriends from './Pages/MeetNewFriends';
import NavigatingYourFamilyTree from './Pages/NavigatingYourFamilyTree';
import ForTheRecord from './Pages/ForTheRecord';
import GiveSomeoneElseAHint from './Pages/GiveSomeoneElseAHint';
import GiveMeAHint from './Pages/GiveMeAHint';

function App() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
 

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <div className="App">
            <Header />
            <div className={isSidebarOpen ? 'active' : ''} id="sidebar">
                <div className="custom-menu">
                    <button type="button" id="sidebarCollapse" className="btn btn-primary" onClick={toggleSidebar}>
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <Navbar />
            </div>
            <div id={isSidebarOpen ? "" : "content"} className={`p-4 p-md-5 pt-5 `}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/readysetgo" element={<ReadySetGo />} />
                    <Route path="/sharingstories" element={<SharingStories />} />
                    <Route path="/thestoryofyou" element={<TheStoryofYou />} />
                    <Route path="/yourerelatedtome" element={<YoureRelatedtoMe />} />
                    <Route path="/navigatingyourfamilytree" element={<NavigatingYourFamilyTree />} />
                    <Route path="/awalkdownmemorylane" element={<AWalkDownMemoryLane />} />
                    <Route path="/makingconnections" element={<MakingConnections />} />
                    <Route path="/whereintheworld" element={<WhereInTheWorld />} />
                    <Route path="/myclaimtofame" element={<MyClaimToFame />} />
                    <Route path="/atriptothetemple" element={<ATripToTheTemple />} />
                    <Route path="/amatchinggame" element={<AMatchingGame />} />
                    <Route path="/makecomputerssmarter" element={<MakeComputersSmarter />} />
                    <Route path="/speedyindexing" element={<SpeedyIndexing />} />
                    <Route path="/getinvolvedapp" element={<GetInvolvedApp />} />
                    <Route path="/meetnewfriends" element={<MeetNewFriends />} /> 
                    <Route path="/givemeahint" element={<GiveMeAHint />} />
                    <Route path="/givesomeoneelseahint" element={<GiveSomeoneElseAHint />} />
                    <Route path="/fortherecord" element={<ForTheRecord />} />

                    {/* <Route path="/comingsoon" element={<ComingSoon />} /> */}
                    
                </Routes>
            </div>
        </div>
    );
}

export default App;
