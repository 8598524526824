import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imageSrc1 from '../images/Fame1.png'
import imageSrc2 from '../images/fame2.png'
import imageSrc3 from '../images/RibbonClaimToFame.png'




function MyClaimToFame() {

    useEffect(() => {
        window.scrollTo(0, 55);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);


    return (
        <div  className="p-4 p-md-5 pt-5">
             <div className='sidebysidetitle'>
                <h2 className="mb-4">My Claim to Fame (5 mins)</h2>
                <img src={imageSrc3} alt='illustration' style={{width:'20%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>

            </div>
            <hr style={{color: 'blue'}}></hr>
            <br></br>

            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                <h4 className="mb-4">Famous Relatives</h4>
                <p style={{color:'blue'}}>Family Tree App &gt; More Menu &gt; Family History Activities &gt; Famous Relatives</p>
                <p>Nearly everyone wants to know if they are related to famous people in history. If you are connected to the shared family tree, you can use FamilySearch to see who you're related to! It's also a super fun way to get your friends talking about their family history. </p>
            </div>

            <br></br>
            <br></br>

            <div className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>
            <div class='graycolor2'>
            <br></br>
            <div class="sidebyside" style={{ display: 'flex', justifyContent: 'center',}}>
            <p style={{ width :'300px'}}>
            <div style={{fontWeight: 'bold', fontSize: '22px'}}>Let's Do It</div>
            
            <br></br>
            
            Open the Family Tree app to More&gt;Family History Activities&gt;Famous Relatives and take a few minutes to see which famous people you are related to!  
            </p>
            <img src={imageSrc1} alt="illustration" style={{width: '50%', height: 'auto',}}></img>
            </div>
            </div>
            </div>
            <br></br>
            <br></br>

            <div>
           
            <div class='sidebyside'>
            <img src={imageSrc2} alt="illustration" style={{width: '50%', height: 'auto'}} className="fade-in"></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"Sometimes it takes a hook to show someone how fun it can be to discover more about their family. Learning about famous relatives can be that hook. More importantly, it can get your friends wondering about those not-so-famous, but equally important people who paved the way for them to become who they are today."<br></br><i style={{color: 'blue'}}>~ FamilySearch</i></p>
            
            </div>
            </div>
            <br></br>

            <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>Share a screenshot of your famous relative discovery on your social media account. Then think of someone who would enjoy discovering their famous relatives. Invite them to try the activity themselves using the Family Tree app or online at: <a href="https://www.familysearch.org/discovery/famousrelatives" target="_blank">https://www.familysearch.org/discovery/famousrelatives</a></p>
            </div>
            <br></br>
            <br></br>

            <hr style={{color: 'blue'}}></hr>

            <p>Good job! Continue to the next activity: <b>A Trip to the Temple</b></p>

            <div style= {{textAlign: 'right'}}>
            <Link to="/atriptothetemple">
            <button className='round-button'>Next Activity</button>
            </Link>
            </div>

            </div>
            
    );
}

export default MyClaimToFame;