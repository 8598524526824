import React, {useEffect, useState} from 'react';
import imgsource1 from '../images/GreenTemple2.png'
import imgsource2 from '../images/LongLostCousins.png'
import imgsource4 from '../images/TaketotheTemple.png'
import imgsource5 from '../images/TaketotheTemple4.png'
import imgsource6 from '../images/Templeroad.png'
import { Link } from 'react-router-dom';



function ATripToTheTemple() {

    useEffect(() => {
        window.scrollTo(0, 50);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
            
            <div className='sidebysidetitle'>
                <h2 className="mb-4">A Trip to the Temple (10 mins)</h2>
                <img src={imgsource6} alt='illustration' style={{width:'20%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>

            </div>
                <hr style={{color: 'blue'}}></hr>
                <br></br>
                <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                    <h4 className="mb-4">Making it Personal</h4>
                    <p style={{color:'blue'}}>Family Tree App &gt; Tasks</p>
                    <p>Have you ever taken the name of one of your own ancestors to the temple? It's a unique and amazing experience, 
                        and even though finding names can be tricky, there are several different methods you can try! This module will teach 
                        you three strategies that make finding names quick and easy.</p>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <div className='sidebyside'>
                <img src={imgsource1} alt="illustration" style={{width: '35%', height: 'auto', paddingRight:'100px' ,paddingLeft: '0px'}} className="fade-in"></img>
                <div style={{ width: '325px' }} className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                <div style={{ fontWeight: 'bold', fontSize: '24px' }}>1. Green means Go!</div>
                <br></br>
                <p>
                A green temple icon next to a person in your tree means they are missing temple ordinances! By tapping on the icon you can see what ordinances need to be performed and reserve them.
                </p>
                </div>
                </div>
                <br></br>
                <br></br>
                <br></br>


                <div className='sidebyside graycolor3'>
                <div style={{ width: '325px' }}>
                <br></br>
                <div style={{ fontWeight: 'bold', fontSize: '24px' }}>2. Long Lost Cousins</div>
                <br></br>
                <p>
                Does it seem like everyone in your tree has received all their temple ordinances? Try this.
                Choose any ancestor from your tree. (It works best if you choose an ancestor back many generations, around 1800, if possible.) 
                Tap the three dots in the top right on their person page, and view "Descendants with Tasks." Adjust the number of generations 
                displayed and filter the search to temples to lead you to cousins who are needing temple work.

                </p>
                <br></br>
                </div>
                 <img src={imgsource2} alt="illustration" style={{width: '50%', height: 'auto', paddingLeft: '75px'}} className="fade-in"></img> 
                </div>
                <br></br>
                <br></br>
                <br></br>
                <div className='notgraycolor' style={{width:'75%'}}>
                <div style={{ fontWeight: 'bold', fontSize: '24px' }}>3. Ordinances Ready</div> 
                <br></br>
                <p>
                Having any luck yet? If not, the Family Tree app will find names for you to take to the temple through the Ordinances 
                Ready function. Tap on the temple icon on the bottom of your screen, then on Ordinances Ready, and follow the prompts. 
                Ordinances Ready starts by searching your temple reservation list, then for people who are related to you that need 
                temple ordinances, and finally for people who aren't related to you whose ordinances have been shared with the temple. 
                </p>
                </div>
                <br></br>
                <br></br>
                <div className='infobox'>
                    
                    <p><span className="fa fa-info-circle mr-3"></span> Adding information to the family tree helps make more connections and prepares more names for temple ordinances.
                        One way to add information is to look for individuals with blue icons by their name. This indicates available 
                        historical records that likely need to be attached to the individual's record. Later we'll tell you more about 
                        how important these records can be and what to do with them.</p>
                </div>
                <br></br>
                <br></br>
                <div className='sidebyside'>
                <img src={imgsource4} alt="illustration" style={{width: '35%', height: 'auto', paddingRight: '25px'}} className="fade-in"></img>
                <div style={{ width: '325px', paddingLeft: '25px'}}>
                <div style={{ fontWeight: 'bold', fontSize: '24px' }}>Printing Temple Names</div>
                <br></br>
                <p>
                You'll need printed temple cards to perform temple ordinances. Under the temple tab, you will find names that you have
                reserved for ordinances. Select the ancestors you are ready to do work for, then tap "Take to the Temple". 
                <br></br>
                Select the names you want and follow the prompts to print the card(s). 
                </p>
                </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Let's Do It</span><br></br><br></br>
                Try one or more of the methods above to find relatives needing temple ordinances. Pray to be guided to names of 
                relatives who are, as Sister Wendy W. Nelson says, "desperate to make covenants with God and to receive their 
                essential ordinances." Reserve the names you find and perform their temple work for them!
            </p>
            <br></br>
            </div>  
            <div class='sidebyside'>
            <img src={imgsource5} alt="illustration" style={{ width: '50%', height: 'auto', }} />
            <div>
            <h4 className='mb-4'>How is this Gathering Israel?</h4>
            <p>
            “Any work you do in the temple is time well spent, but receiving ordinances vicariously for one of your own ancestors
             will make the time in the temple more sacred, and even greater blessings will be received."<br></br>
            <i style={{ color: 'blue' }}>
            ~ Elder Richard G. Scott
            </i>
            </p>
            </div>
            </div>
            
            <br></br>
            <div className='graycolor2'>
                    <br></br>
                <p><span className='h4 mb-4'>Invitation</span><br></br><br></br>
                Schedule a time to take the names you find to the temple and invite a friend or family member to go with you! 
                If you want to talk about the temple with friends or family who are not members of the Church, consider inviting them to visit 
                the temple grounds with you where you can explain why we have temples and how you feel about them.
            </p>
            <br></br>

            </div>
            <br></br>
            <br></br>
            <hr style={{color: 'blue'}}></hr>
            <p>Good job! Continue to the next activity: <b>A Matching Game</b></p>
            <div style= {{textAlign: 'right'}}>
        <Link to="/amatchinggame">
        <button className='round-button'>Next Activity</button>
        </Link>
        </div>

            </div>

    );
}

export default ATripToTheTemple;