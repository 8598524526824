import React, {useEffect, useState} from 'react';
import imgSrc6 from '../images/FSLogo.png';
import imgsource7 from '../images/Screenshot 2024-03-16 at 2.44.28 PM.png'
import { Link } from 'react-router-dom';

function ReadySetGo() {
   

    useEffect(() => {
        window.scrollTo(0,25);
    }, []);
 
    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);

    return (

        <div>
            
         
        
        <div className="p-4 p-md-5 pt-5">
        
                <div className='sidebysidetitle'><h2 className="mb-4">Ready. Set. Go!</h2>
                <img src={imgsource7} alt='testingairplane' style={{width:'25%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>
                </div>
                
            
            
            <hr style={{ color: 'blue' }}></hr>
            <br></br>

            <div className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>
                <h4 className="mb-6">Welcome!</h4>
                <br></br>
                <p>Think gathering Israel is hard? Think again! Follow these modules to learn easy skills
                    and connect with your friends and family.</p>
            </div>

            <br></br>

            <div className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>
                <p><h5 className='mb-4'>Here's how it works:</h5></p>
            </div>

            <ol>
                <li className={`slide-in-2 ${isSlideInVisible ? 'active' : ''}`}>Read a brief introduction</li>
                <li className={`slide-in-3 ${isSlideInVisible ? 'active' : ''}`}>Try a new skill or activity</li>
                <li className={`slide-in-4 ${isSlideInVisible ? 'active' : ''}`}>See how what you learned helps gather Israel</li>
                <li className={`slide-in-5 ${isSlideInVisible ? 'active' : ''}`}>Share what you've learned with a friend!</li>
            </ol>
            <br></br>
            <p className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>The activities build on one another, so it is best to go through them in order.
                Times listed are estimated but you can spend as much time as you want.
                The more you practice, the more confident you'll become!
            </p>
            <br></br>
            <br></br>

            
            <div class='sidebyside'>
                <div class='image'>
                    <img className= 'fade-in' src={imgSrc6} alt='FamilySearch Logo' style={{width:'80%', height: 'auto', padding: '10px'}}></img>
                    </div>

                <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                    <p><b>Please Note:</b> Most of the activities require the Family Tree app. If you already have it, you're all set! If not, download the app using the links below, then log in using your Church or FamilySearch account name and password.</p>
                    <br></br>
                    <a href="https://apps.apple.com/us/app/familysearch-tree/id885982973#:~:text=Free-,View,-in%20Mac%20App" target="_blank">Download FamilySearch on App Store</a>
                    <br></br>
                    <br></br>
                    <a href="https://play.google.com/store/search?q=familysearch&c=apps" target="_blank">Download FamilySearch on Google Play</a>
                    </div>
            </div>

            <br></br>
            <br></br>
            <br></br>

            <div class='graycolor2'>
            
                <h4 className="mb-5">For Classroom Settings:</h4>
                <p>You can do these activities on your own or with others, like in a seminary or institute class.
                    Here are some ideas for classroom use:</p>
                <ul>
                    <li>Do one activity during the last 10 minutes of class, then invite participants to share what
                        they learned with a friend outside of class. Have them share their experience with the class
                        the next time you meet.</li>
                    <li>Let students lead the activities so they can learn as they teach others.</li>
            
                </ul><br></br>

            
            </div>
         <br></br>
         <br></br>
         <div style={{textAlign:'center'}}>
            <Link to="/sharingstories">
            <button className='round-button'>Start the First Activity!</button>
            </Link>
         </div>   
         
           
        
        </div>
        </div>
    
    );
}

export default ReadySetGo;