import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imageSrc1 from '../images/AMatchingGame_1.png';
import imageSrc2 from '../images/AMatchingGame_2.png';
import imgSrc3 from '../images/Magnifying.png'




function AMatchingGame() {

    useEffect(() => {
        window.scrollTo(0, 42);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => setIsSlideInVisible(true), 500);
      return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
            
            <div className='sidebysidetitle'>
                <h2 className="mb-4">A Matching Game (5 mins)</h2>
                <img src={imgSrc3} alt='illustration' style={{width:'7%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>
                </div>
            <hr style={{color: 'blue'}}></hr>
            <br></br>

            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>

                <h4 className="mb-4">Improve Place-Names</h4>
                <p style={{color:'blue'}}>Family Tree App &gt; More Menu &gt; Improve Place-Names</p>
                <p>The next few activities offer some very worthwhile ways to kill a bit of free time. This could include times where you are standing in line, waiting for a ride, or riding the bus. These microtasks will teach you more about historical records (where details like birth, marriage, and death information is recorded) while helping others to find their ancestors. They're so easy that nearly anyone can do them.</p>
                <p>The first task is called Improve Place-Names. To Improve Place-Names, you compare a user-entered place (like where a person was born or where they died) to a standard place name that matches. Doing this helps improve the accuracy and standardizes the records on FamilySearch, making them easier to search and easier to find. In the time it took you to read this description, you could have finished several matches.</p>
            </div>

            <br></br>
            <br></br>
            <br></br>

            <div className={`slide-in-left ${isSlideInVisible ? 'active' : ''}`}>
            <div className="graycolor2">
            <div className='sidebyside'>
            <br></br>
            <p><h4 className="mb-4">Let's Do It</h4>
            Take a few minutes to Improve Place-Names. In the FamilyTree app, go to the More menu and click on Improve Place-Names. Then follow the instructions to get started.<br></br><br></br>
            You can work on improving records from the country of your choice. If you know a part of the world better than others, look for records from that location.<br></br><br></br>
            If you aren't sure about a place, tap the skip button. (Place-names often change over time, so pay attention to the dates on the records and the dates on the place to be sure they match.) Look up names online to check answers you aren't sure about. Batches include 10 Place-Names, which might only take a few minutes, so feel free to do as many or as few as you have time for.</p>
            <img src={imageSrc1} alt="illustration" style={{width: '50%', height: 'auto'}} className="fade-in"></img>
            </div>
            </div>
            </div>
            <br></br>
            <br></br>

            <div>
           
            <div class='sidebyside'>
            <img src={imageSrc2} alt="illustration" style={{width: '50%', height: 'auto', zIndex: '-1'}} className="fade-in"></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"The small and simple things you choose to do today will be magnified into great and glorious blessings tomorrow."<br></br> <i style={{color: 'blue'}}>~ Ann M. Dibb</i></p>
            </div>
            </div>
            <br></br>

            <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>Next time you have 5-10 minutes to scroll through social media, use that time to improve place names instead. Be sure to invite your friends to do it too! They will feel good knowing that they're doing something to help others, and they may start to ask more questions as well!</p>
            </div>
            <br></br>
            <br></br>

            <hr style={{color: 'blue'}}></hr>

            <p>Good job! Continue to the next activity: <b>Make Computers Smarter</b></p>

            <div style= {{textAlign: 'right'}}>
            <Link to="/MakeComputersSmarter">
            <button className='round-button'>Next Activity</button>
            </Link>
            </div>

        </div>
    );



    return (
        <div></div>
    );
}

export default AMatchingGame;