import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
    // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation();

    // const toggleSidebar = () => {
    //     setIsSidebarOpen(!isSidebarOpen);
    // }

    return (
        <nav id="sidebar2" >
            {/* <div className="custom-menu"> */}
                {/* <button type="button" id="sidebarCollapse" className="btn btn-primary" onClick={toggleSidebar}>
                    <i className="fa fa-bars"></i>
                    <span className="sr-only">Toggle Menu</span>
                </button> */}
            {/* </div> */}
            <div className="p-4">
                <h1><a className="logo">Who Me, Gather Israel?</a></h1>
                <ul className="list-unstyled components mb-5">
                    <li className={location.pathname === '/' ? 'active' : ''}>
                        <Link to="/" className="nav-link"><span className="fa fa-home mr-3"></span>Home</Link>
                    </li>
                    <li className={location.pathname === '/readysetgo' ? 'active' : ''}>
                        <Link to="/readysetgo" className="nav-link"><span className="fa fa-paper-plane mr-3"></span> Ready. Set. Go!</Link>
                    </li>
                    <li className={location.pathname === '/sharingstories' ? 'active' : ''}>
                        <Link to="/sharingstories" className="nav-link"><span className="fa fa-book mr-3"></span> Sharing Stories</Link>
                    </li>
                    <li className={location.pathname === '/thestoryofyou' ? 'active' : ''}>
                        <Link to="/thestoryofyou" className="nav-link"><span className="fa fa-user mr-3"></span> The Story of You</Link>
                    </li>
                    <li className={location.pathname === '/yourerelatedtome' ? 'active' : ''}>
                        <Link to="/YoureRelatedtoMe" className="nav-link"><span className="fa fa-wifi mr-3"></span> You're Related to Me?!</Link>
                    </li>
                    <li className={location.pathname === '/navigatingyourfamilytree' ? 'active' : ''}>
                        <Link to="/navigatingyourfamilytree" className="nav-link"><span className="fa fa-compass mr-3"></span> Navigating Your Family Tree</Link>
                    </li>
                    <li className={location.pathname === '/awalkdownmemorylane' ? 'active' : ''}>
                        <Link to="/awalkdownmemorylane" className="nav-link"><span className="fa fa-road mr-3"></span> A Walk Down Memory Lane</Link>
                    </li>
                    <li className={location.pathname === '/makingconnections' ? 'active' : ''}>
                        <Link to="/makingconnections" className="nav-link"><span className="fa fa-chain mr-3"></span> Making Connections</Link>
                    </li>
                    <li className={location.pathname === '/whereintheworld' ? 'active' : ''}>
                        <Link to="/whereintheworld" className="nav-link"><span className="fa fa-globe mr-3"></span> Where In The World?</Link>
                    </li>
                    <li className={location.pathname === '/myclaimtofame' ? 'active' : ''}>
                        <Link to="/myclaimtofame" className="nav-link"><span className="fa fa-diamond mr-3"></span> My Claim To Fame</Link>
                    </li>
                    <li className={location.pathname === '/atriptothetemple' ? 'active' : ''}>
                        <Link to="/atriptothetemple" className="nav-link"><span className="fa fa-car mr-3"></span> A Trip To The Temple</Link>
                    </li>
                    <li className={location.pathname === '/amatchinggame' ? 'active' : ''}>
                        <Link to="/amatchinggame" className="nav-link"><span className="fa fa-sticky-note mr-3"></span> A Matching Game</Link>
                    </li>
                    <li className={location.pathname === '/makecomputerssmarter' ? 'active' : ''}>
                        <Link to="/makecomputerssmarter" className="nav-link"><span className="fa fa-graduation-cap mr-3"></span> Make Computers Smarter</Link>
                    </li>
                    <li className={location.pathname === '/speedyindexing' ? 'active' : ''}>
                        <Link to="/speedyindexing" className="nav-link"><span className="fa fa-rocket mr-3"></span> Speedy Indexing</Link>
                    </li> 
                    <li className={location.pathname === '/getinvolvedapp' ? 'active' : ''}>
                        <Link to="/getinvolvedapp" className="nav-link"><span className="fa fa-mobile-phone mr-3"></span> Get Involved App</Link>
                    </li>
                    <li className={location.pathname === '/meetnewfriends' ? 'active' : ''}>
                        <Link to="/meetnewfriends" className="nav-link"><span className="fa fa-comment mr-3"></span> Meet New Friends</Link>
                    </li>
                    
                    {/* <li className={location.pathname === '/comingsoon' ? 'active' : ''}>
                        <Link to="/comingsoon" className="nav-link"><span className="fa fa-cogs mr-3"></span> Coming Soon!</Link>
                    </li> */}

                    <br></br>
                    <li>
                    <span className="fa fa-trophy mr-3"></span> Challenge Yourself
                    </li>
                    <br></br>
                    
                    <li className={location.pathname === '/givemeahint' ? 'active' : ''}>
                        <Link to="/givemeahint" className="nav-link"><span className="fa fa-question mr-3"></span> Give Me A Hint</Link>
                    </li>
                    <li className={location.pathname === '/givesomeoneelseahint' ? 'active' : ''}>
                        <Link to="/givesomeoneelseahint" className="nav-link"><span className="fa fa-question mr-3"></span> Give Someone Else A Hint</Link>
                    </li>
                    <li className={location.pathname === '/fortherecord' ? 'active' : ''}>
                        <Link to="/fortherecord" className="nav-link"><span className="fa fa-pencil mr-3"></span> For The Record...</Link>
                    </li>

                    
                    {/* Add other sidebar links with React Router Links */}
                </ul>
                {/* <div className="mb-5">
                    <h3 className="h6 mb-3">Subscribe for newsletter</h3>
                    <form action="#" className="subscribe-form">
                        <div className="form-group d-flex">
                            <div className="icon"><span className="icon-paper-plane"></span></div>
                            <input type="text" className="form-control" placeholder="Enter Email Address" />
                        </div>
                    </form>
                </div> */}
                <div className="footer">
      <p>
        {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
        Copyright &copy;{new Date().getFullYear()} All rights reserved | This template is made with{' '}
        <i className="icon-heart" aria-hidden="true"></i> by{' '}
        <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">Colorlib.com</a>
        {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
      </p>
    </div>
                
            </div>
        </nav>
    );
}

export default Navbar;
