import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import './bootstrap-sidebar-05/sidebar-05/css/bootstrap.min.css'; 
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';


import App from './App';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <Router>
    <App />
  </Router>
);