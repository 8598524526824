import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import vidSrc1 from '../videos/Elder Bednar Rootstech.mp4'
import imageSrc5 from '../images/SharingStories.png';
import imageSrc2 from '../images/Screenshot 2024-03-16 at 3.51.47 PM.png';

function SharingStories() {

    useEffect(() => {
        window.scrollTo(0, 63);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);
    
    return (
        <div className="p-4 p-md-5 pt-5">
            <div className='sidebysidetitle'>
                <h2 className="mb-4">Sharing Stories (5 mins)</h2>
              
                <img src={imageSrc2} alt='books' style={{width:'25%', height:'auto', marginLeft:'auto',padding:'0px'}}></img>
              
            </div>
            
            <hr style={{color: 'blue'}}></hr>
            <br></br>

            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
                <h4 className='mb-4'>Look how easy it is to get started!</h4>
                <p>Talking to others about your family may seem challenging at first, but it can make all the difference in bringing others closer to Christ. Watch how Elder and Sister Bednar were able to create a simple but powerful experience by just sharing a story.</p><br></br>   
                    {// <iframe src={vidSrc1} title="Video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    }
            </div>
            <div className='fade-in'>
            <center><video src={vidSrc1} controls width="75%" height="auto"></video></center>
            </div>

            <br></br>
            <br></br>
            <br></br>

            <div class='graycolor2'>
            <br></br><h4 className="mb-4">Let's Do It</h4>
            <p>Now it's your turn to try the activity from the video. Identify an ancestor who is important to you and take a couple of minutes to share something about them with a friend. It can be a story, a favorite memory, a photo, or anything else you find interesting. When you're done, invite your friend to do the same with you.</p>
            <ul>
                    <li>What did you learn about your friend?</li>
                    <li>Who else do you know that would benefit from hearing the story you shared?</li>
            
            </ul>
            </div>
            <br></br>
            <br></br>

            <div>
          
            <div className='sidebyside'>
            <img src={imageSrc5} alt="illustration" style={{width: '50%', height: 'auto'}}></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"People can often feel the Spirit as they talk about families. Many want a stronger connection with their extended family. This can lead to a desire to find a connection and identity as part of God's family." <br></br><span style={{fontStyle: 'italic', color: 'blue', textAlign: 'right'}}>~ Preach My Gospel</span></p>
            </div>
            </div>
            <br></br>

        

            <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>Think about someone else you could have this experience with. Now go and share it with them!</p>
            <p>Tip: Become familiar with some of your ancestors now so you're always prepared to share with others when the opportunity arises or the Spirit prompts you to do so.</p>
            </div>
            <br></br>
            <br></br>

            <hr style={{color: 'blue'}}></hr>

            <p>Good job! Continue to the next activity: <b>The Story of You</b></p>

            <div style= {{textAlign: 'right'}}>
            <Link to="/thestoryofyou">
            <button className='round-button'>Next Activity</button>
            </Link>
            </div>

        </div>
    )
            }

export default SharingStories;