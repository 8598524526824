import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imgSrc1 from '../images/GetInvolvedApp_1.jpg'
import imgSrc2 from '../images/GetInvolvedApp_2.png'
import imgSrc3 from '../images/Superphone.png'




function GetInvolvedApp() {

    useEffect(() => {
        window.scrollTo(0, 55);
    }, []);

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => setIsSlideInVisible(true), 500);
    return () => clearTimeout(timer);
    }, []);

    return (
        <div className="p-4 p-md-5 pt-5">
        <div>
        <div className='sidebysidetitle'>
                <h2 className="mb-4">Get Involved App (5 mins)</h2>
                <img src={imgSrc3} alt='illustration' style={{width:'25%', height:'auto',marginLeft:'auto',padding:'0px'}}></img>
                </div>
            <hr style={{color: 'blue'}}></hr>
            <br></br>
        </div>

        <div className='fade-in' style={{marginLeft: '110px', marginRight: '110px'}}>
        <div className='infobox'> 
            <p><span className="fa fa-info-circle mr-3"></span> NOTE: For this activity, you will need to download the FamilySearch "Get Involved" App!
            </p>
        </div>
        </div>

        <div className='sidebyside'>
    
            <img src={imgSrc1} alt="illustration" style={{width: '30%', height: 'auto', marginTop: '100px', marginRight: '100px'}} className='fade-in'></img>
            
            <div className='mb-4'>
            <h4 className={`slide-in ${isSlideInVisible ? 'active' : ''}`} style={{marginTop: '100px'}}>Download the "Get Involved" FamilySearch app</h4>
            <ul>
                <li className={`slide-in-2 ${isSlideInVisible ? 'active' : ''}`}><a href="https://apps.apple.com/us/app/familysearch-get-involved/id1567446332" target="_blank">App Store</a></li>
                <li className={`slide-in-3 ${isSlideInVisible ? 'active' : ''}`}><a href="https://play.google.com/store/apps/details?id=org.familysearch.mobile.idx.review&hl=en_US&gl=US" target="_blank">Google Play</a></li>
            </ul>
            </div> 

        </div>

        <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`} style={{marginTop: '100px'}}>
            <h4 className='mb-4'>Index from your phone</h4>
            <p>This activity is similar to the previous activity "Speedy Indexing." It is done in the FamilySearch Get Involved App. FamilySearch's computers read the documents, but they often make mistakes. Your job is to review the names and fix the mistakes. It's as simple as that! You can do as many or as few as you want.</p>
        </div>

        <div className="graycolor2" style={{marginTop: '80px'}}>
            <h4 className="mb-4">Let's Do It</h4>
            <p>Open the Get Involved app on your phone. Follow the prompts given to you. If you are still unsure how to use the app, go to the More Menu and then "Learn How to Review Names" for a brief tour and practice.
            </p>
            <br></br>
            <p>Pro tip: If you can't read a name, don't worry! Just skip it and keep moving.</p>
        </div>

        <div class='sidebyside'>
            <img src={imgSrc2} alt="illustration" style={{width: '50%', height: 'auto'}} className="fade-in"></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"As you perform temple ordinances for family members, the spirit of Elijah, 'a manifestation of the Holy Ghost bearing witness of the divine nature of the family,' will knit the hearts of your fathers, mothers, and children together in love." <br></br><i style={{color: 'blue'}}>~ Elder Gerrit W. Gong</i></p>
        </div>

        <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>Try typing in your last name or the place that you live or have a connection to. Review names for that name or place. Remember to do this activity when you have a few spare minutes on your hands!</p>
        </div>

        <hr style={{color: 'blue'}}></hr>


        {/* footer */}
        <p>Good job! Continue to the next activity: <b>Meet New Friends</b></p>

        <div style= {{textAlign: 'right'}}>
        <Link to="/meetnewfriends">
        <button className='round-button'>Next Activity</button>
        </Link>
        </div>

        </div>
    );
}

export default GetInvolvedApp;