import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import patrick from '../images/Patrick.png';

function ComingSoon() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="p-4 p-md-5 pt-5">
            <h2 className="mb-4">Coming Soon!</h2>
            <hr style={{color: 'blue'}}></hr>
            <br></br>
            <p style={{fontSize: '20px'}}>Make sure to check back regularly as we are constantly adding new materials! Thank you for your patience!</p>
            <br></br>
            
            <img src={patrick} alt="morecomingsoon" ></img>
            <br></br>
            <br></br>

            <div style= {{textAlign: 'left'}}>
            <Link to="/">
            <button className='round-button'>Return To Home Page</button>
            </Link>
            </div>
            
            </div>
    );
}

export default ComingSoon;