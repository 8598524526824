import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import imageSrc1 from '../images/TheStoryOfYou_1.png';
import imageSrc2 from '../images/TheStoryOfYou_2.jpg';
import imageSrc3 from '../images/TheStoryOfYou_3.png';
import imageSrc4 from '../images/TheStoryOfYou_4.jpg';
import imageSrc5 from '../images/TheStoryOfYou_5.png';
import imageSrc6 from '../images/StoryOfYouBook.png';




function TheStoryofYou() {

    //this scrolls to the top when the user first enters the page
    useEffect(() => {
        window.scrollTo(0, 65);
    }, []);

    

    // this is for the slide in effect
    const [isSlideInVisible, setIsSlideInVisible] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => setIsSlideInVisible(true), 500);
      return () => clearTimeout(timer);
    }, []);




    return (
        <div className="p-4 p-md-5 pt-5" >
            <div className='sidebysidetitle'>
                <h2 className="mb-4">The Story of You (5 min)</h2>
                <img src={imageSrc6} alt="person" style={{width: '8%', height: 'auto', marginLeft:'auto', padding: '0px'}}></img>
                </div>
            
            <hr style={{color: 'blue'}}></hr>
            <br></br>
            <div/>
            <div className={`slide-in ${isSlideInVisible ? 'active' : ''}`}>
            <h4 className="mb-4">Did you know these facts about yourself?</h4>
            <p style={{color:'blue'}}>Family Tree App &gt; More Menu &gt; Family History Activities &gt; All About Me</p>
            <p>Ever wondered how many people in your family tree share your name? 
                Who won the NBA championship when you were born?
                 What movies and music were popular back then? 
                 Try All About Me to learn more about you!</p>
            </div>
            <br></br>
            <br></br>

            <div className="graycolor2">
                <br></br>
            <h4 className="mb-4">Let's Do It</h4>
            <p>Take a few minutes to explore the fun facts in this activity. 
            Then think of someone you know who would also enjoy learning about themselves!</p>
            <br></br>
            <img src={imageSrc1} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>
            <img src={imageSrc2} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>
            <img src={imageSrc3} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>
            <img src={imageSrc4} alt="illustration" style={{width: '25%', height: 'auto', padding: '10px'}} className="fade-in"></img>
            <br></br>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div>
           
            <div class='sidebyside'>
            <img src={imageSrc5} alt="illustration" style={{width: '50%', height: 'auto'}} className="fade-in"></img>
            <p><span className='h4 mb-4'>How is this Gathering Israel?</span><br></br><br></br>"Human beings desire attachment, belonging, and connection...The more we discover about our past, 
                the greater connection we feel to our ancestors. As we record our own history,
                 we open the opportunity for future generations to connect with us when we are gone." <br></br><i style={{color: 'blue'}}>~ FamilySearch</i></p>
            </div>
            </div>
            <br></br>

            <div class='graycolor2'>
            <h4 className="mb-4">Invitation</h4>
            <p>Share a screenshot of something you've learned about yourself with another person.
            Invite them to try the activities themselves by using the link below.
            Share your cool facts on social media!</p>
            <a href="https://www.familysearch.org/discovery/about">familysearch.org/discovery/about</a>
            <br></br>
            <br></br>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <hr style={{color: 'blue'}}></hr>


            {/* footer */}
            <p>Good job! Continue to the next activity: <b>You're Related to Me?!</b></p>

            <div style= {{textAlign: 'right'}}>
            <Link to="/YoureRelatedToMe">
            <button className='round-button'>Next Activity</button>
            </Link>
            </div>

        </div>
    );
}

export default TheStoryofYou;