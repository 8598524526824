import './flipcard.css';
import Card from './card'
function flipcard() {
    return (
        <div className="flipcard-container">
            <Card/>
        </div>

    )

}
export default flipcard;